.mediaSlider{
  > h2,
  > p{
    &:empty{
      display: none;
    }
  }
}

.sn-media-slider {

  .slider-pagination {

    .paging-item {
      background-color: #FFF;
      width: 20px;
      height: 20px;
      padding: 4px;
      box-sizing: content-box;
      background-clip: content-box !important;

      &.flex-active,
      &:hover{
        background-color: $secondary-color;
      }

    }

  }

  .slider-pagination{
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    height: auto;
  }

  .slider {

    &.bottom-left-paging,
    &.bottom-center-paging,
    &.bottom-right-paging{
      margin-bottom: 0;
    }

  }

}

.custom-media-slider{

  .sn-media-slider .slider {

    .slide-title {
      margin: 3% 0 0;
      text-transform: uppercase;
    }

    .slide-description {
      margin: 0;
      font-weight: 400;
      text-shadow: 1px 1px 2px rgba(0,0,0,.5);
    }

    .slide .slide-overlay {
      padding: 1% 15%;
    }

    .slide-text {

      &.bottom-left,
      &.bottom-right,
      &.bottom-center { padding-bottom: 10%; }

    }

  }

  .slide-link-custom {
    position: absolute;
    width: 100%;
    bottom: 10%;
    left: 0;
    padding: 0 15%;
  }

  .media-slider-button{
    @include link-element;
    margin-top: 20px;
    display: inline-block;
    font-size: 16px;

    @media screen and (max-width: 768px){
      font-size: 12px;
      padding: 10px 20px;
    }

  }

}




// .mediaSlider{
//   margin-bottom: 0;
//   > h2,
//   > p{
//     &:empty{
//       display: none;
//     }
//   }
// }
// .sn-media-slider{
//   // .slides .slide .slide-overlay{
//   //   padding: 0;
//   // }
//   .slider{
//     // .slide-text{
//     //   max-width: $maxWidth;
//     //   margin: 0 auto;
//     //   display: flex;
//     //   flex-flow: column;
//     //   justify-content: flex-end;
//     //   padding: 0 ($page-element-margin * 2);
//     // }
//     .slide-title{
//       @include special-heading(#FFF);
//     }
//     .slide-description{
//       // padding-left: 40px;
//     }
//     .slide-title,
//     .slide-description{
//       margin: 0;
//       text-shadow: none;
//     }
//   }
// }
// .sn-media-slider .slider.sn-breakpoint-lt-500 .slide-title{
//   font-size: inherit;
// }
//
// .sn-media-slider .slider-pagination .paging-item{
//   background-color: #FFF;
//   width: 20px;
//   height: 20px;
//   padding: 4px;
//   box-sizing: content-box;
//   background-clip: content-box !important;
//   &.flex-active,
//   &:hover{
//     background-color: $secondary-color;
//   }
// }
// .sn-media-slider .slider-pagination{
//   display: flex;
//   flex-flow: row wrap;
//   justify-content: center;
//   align-items: center;
//   height: auto;
// }
// .sn-media-slider .slider.bottom-left-paging,
// .sn-media-slider .slider.bottom-center-paging,
// .sn-media-slider .slider.bottom-right-paging{
//   margin-bottom: 0;
// }
// .sn-media-slider .slider.bottom-center-paging .slider-pagination,
// .sn-media-slider .slider.bottom-center-paging .slider-pagination-numbers{
//   bottom: 20px;
// }
//
// @media screen and (max-width: 420px){
//   .pageEl:not(.custom-media-slider) .sn-media-slider .slider.bottom-center-paging .slider-pagination,
//   .pageEl:not(.custom-media-slider) .sn-media-slider .slider.bottom-center-paging .slider-pagination-numbers{
//     bottom: 0;
//   }
// }
// @media screen and (max-width: 360px){
//   .pageEl:not(.custom-media-slider) .sn-media-slider .slider-pagination .paging-item{
//     width: 14px;
//     height: 14px;
//   }
// }
//
//
//
// .custom-media-slider{
//   &.extra-small{
//     .slider{
//       height: 300px !important;
//       @media screen and (max-width: 768px){ height: 200px !important; }
//     }
//   }
//   &.small{
//     .slider{
//       height: 400px !important;
//       @media screen and (max-width: 768px){ height: 300px !important; }
//     }
//   }
//   &.medium{
//     .slider{
//       height: 500px !important;
//       @media screen and (max-width: 768px){ height: 400px !important; }
//     }
//   }
//   .slider{
//     height: 650px !important;
//     @media screen and (max-width: 768px){ height: 400px !important; }
//   }
//   .slides .slide .slide-overlay{
//     padding: 0;
//   }
//   .slider{
//     .slide-text{
//       max-width: $maxWidth;
//       margin: 0 auto;
//       display: flex;
//       flex-flow: column;
//       justify-content: flex-end;
//       padding: ($page-element-margin * 2);
//       &.top-center,
//       &.top-left,
//       &.top-right{
//         justify-content: flex-start;
//       }
//       &.bottom-center,
//       &.bottom-left,
//       &.bottom-right{
//         justify-content: flex-end;
//       }
//       &.middle-center,
//       &.middle-left,
//       &.middle-right{
//         justify-content: center;
//       }
//     }
//   }
//   .sn-media-slider{
//     .top-center-paging,
//     .top-left-paging,
//     .top-right-paging{
//       .slide-text{
//         padding-top: 80px;
//       }
//       .slider-pagination,
//       .slider-pagination-numbers{
//         top: 20px;
//         bottom: auto;
//       }
//     }
//     .bottom-center-paging,
//     .bottom-left-paging,
//     .bottom-right-paging{
//       .slide-text{
//         padding-bottom: 80px;
//       }
//       .slider-pagination,
//       .slider-pagination-numbers{
//         top: auto;
//         bottom: 20px;
//       }
//     }
//     .slider{
//       .slide-title{
//         font-size: 3em;
//         @media screen and (max-width: 768px){
//           font-size: 1.5em;
//         }
//         line-height: 1;
//       }
//       .slide-description{
//         font-size: 24px;
//         @media screen and (max-width: 768px){
//           font-size: 18px;
//         }
//         line-height: 1.4;
//         font-weight: 500;
//         margin-top: 1em;
//       }
//       .media-wrapper{
//         &:before{
//           content: "";
//           position: absolute;
//           top: 0;
//           bottom: 0;
//           left: 0;
//           right: 0;
//           background-color: rgba(0,0,0,.5);
//           // background-image: url(../images/slider-overlay-transparent.svg);
//           background-repeat: repeat;
//           background-size: 8px 8px;
//           z-index: 2;
//         }
//         img{
//           filter: grayscale(50%);
//           @include object-fit(cover, center);
//           left: auto !important;
//           width: 100% !important;
//           height: 100%!important;
//           object-position: center;
//           top: auto !important;
//         }
//       }
//     }
//   }
//   .sn-media-slider .slider-pagination-numbers .paging-item.flex-active{
//     color: #FFF;
//   }
//   .media-slider-button{
//     @include link-element;
//     margin-top: 20px;
//     display: inline-block;
//     font-size: 16px;
//     @media screen and (max-width: 768px){
//       font-size: 12px;
//     }
//   }
// }
