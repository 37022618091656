// MODS FOR MOBILE NAV DISPLAY

html.has-mobile-nav {
	.theme-search-wrapper, .theme-main-nav, .theme-sub-nav,// #sn-site-logo,

	#topNavPlaceholder {
		display: none;
	}
}
// $main-nav-font-size 16
// $main-nav-height 70
// $main-nav-height-fixed 45
$nav-item-before-offset: 10px;
@mixin navItemBefore {
	content: "";
	display: block;
	position: absolute;
	width: 100%;
	height: 2px;
	left: 0;
	bottom: ((($main-nav-height - $main-nav-font-size) / 2) - $nav-item-before-offset);
	box-sizing: border-box;
	background-color: transparent;
	padding: 0 $main-nav-spacing;
	background-clip: content-box;
	transition: background-color $transition-linear;
}
@mixin navItemBeforeHover {
	background-color: $secondary-color;
}
@mixin navItemBeforeFixed {
	bottom: ((($main-nav-height-fixed - $main-nav-font-size) / 2) / 2);
}

.user_mode .theme-nav-item.home {
	width: 0;
	visibility: hidden;
}

#topNav {
	position: absolute;
	min-width: 250px;
	left: 0;
	right: 0;
	font-family: $main-nav-font;
	z-index: 400;

	.collapsed-mobile-nav & {
		top: $account-nav-height-mobile;
	}
	// SEARCH BAR
	.theme-search-bar {
		box-sizing: content-box;
		height: $site-tagline-height;

		.theme-search-box {
			position: relative;
			display: inline-block;
			margin: -$site-search-height 0;
			overflow: hidden;
			@include flex(0, 0, auto);
			height: $site-search-height;

			~.theme-search-box {
				margin-left: $site-search-spacing;
			}
		}

		.theme-search-clear,
		.theme-search-input,
		.theme-search-submit {
			float: left;
			height: $site-search-height;
			line-height: $site-search-height;
			transition: background $transition-linear;
		}

		.theme-search-clear,
		.theme-search-input {
			font-size: $site-search-input-font-size;
		}

		.theme-search-input {
			background: $site-search-input-background;
			border: $site-search-border-width solid $site-search-input-border-color;
			border-right: none;
			padding: 0 ($site-search-height - $site-search-border-width * 2) 0 $site-search-spacing;

			&::-ms-clear {
				display: none;
			}

			&:focus {
				outline: none;
				background: $site-search-input-background-focus;
			}
		}

		.theme-search-submit {
			cursor: pointer;
			color: $site-search-submit-color;
			font-size: $site-search-submit-font-size;
			border: $site-search-border-width solid $site-search-submit-border-color;
			padding: 0 $site-search-spacing;
			background: $site-search-submit-background;

			&:focus,
			&:hover {
				background: $site-search-submit-background-focus;
			}
		}

		.theme-search-clear {
			float: left;
			text-decoration: none;
			text-align: center;
			margin-left: 0 - $site-search-height - $site-search-border-width;
			margin-right: $site-search-border-width;
			width: $site-search-height;

			&:before {
				content: "✕";
				color: #9ca8af;
				font-family: FontAwesome;
				font-size: $site-search-input-font-size;
			}
		}
	}
	// MAIN & SUB NAV SHARED STYLES
	.theme-nav-dropdown,
	.theme-nav-style-dropdown {
		.paywall_image {
			height: 0.75em;
			margin-right: 0.1em;
		}
	}

	.theme-nav-style-dropdown {
		/* Level One */
		padding: 0;
		margin: 0;
		list-style: none;
		display: block;

		> .theme-nav-item,
		> .theme-nav-title {
			padding: 0;
			float: left;
			display: block;
			height: 100%;
		}

		> .theme-nav-title a {
			text-transform: none;
			color: $sub-nav-title-color; // font-weight: 600;
			display: block;
			padding: 0 $sub-nav-title-spacing*.7 0 $sub-nav-title-spacing;
			text-decoration: none;

			&:after {
				content: '\f105';
				font-family: FontAwesome;
				font-size: 1.1em;
				margin-left: $sub-nav-title-spacing;
			}
		}

		> .theme-nav-item {
			position: relative;
			border-right: $main-nav-item-separator;
			cursor: pointer;

			> .theme-nav-link {
				display: inline-block;
				background-color: rgba(0, 0, 0, 0);

				&:hover {
					text-decoration: none;
				}
				// &:before {
				//   @include navItemBefore;
				// }
			}
			// &:hover > .theme-nav-link:before,
			// &.dropdown-open > .theme-nav-link:before,
			// &.selected > .theme-nav-link:before {
			//   @include navItemBeforeHover;
			// }
		}
		// DROPDOWNS (mainnav & subnav)
		.theme-nav-more > a:after {
			content: "+";
			margin-left: 0.3em;
			display: inline-block;
			position: relative;
			top: -.1em;
		}

		.theme-nav-link {
			transition: color $transition-linear, background-color $transition-linear;
		}

		~.theme-nav-dropdown {
			font-size: $dropdown-font-size;
			display: none;
			width: 100%;
			min-width: $dropdown-width;
			position: absolute;
			text-align: left;
			background: $dropdown-color;
			border-top: $dropdown-border; // border-bottom: 3px solid $accent-color;
			z-index: 200;
			transition: opacity $transition-linear, margin-top $transition-linear;

			&.loading:before {
				content: "Loading...";
				font-size: 10px;
				display: block;
				line-height: 20px;
				opacity: 0.5;
				padding: 0 $dropdown-spacing;
			}

			> .theme-nav-item {
				display: table;
				position: relative;
				width: 100%;
				min-height: $dropdown-item-height;
				border-right: 0;
				border-bottom: $main-nav-item-separator;

				&:last-child {
					border-bottom: none;
				}

				&.hasChild > .theme-nav-link {
					padding-right: $dropdown-spacing * 2;

					&:before {
						content: "\f0da";
						display: block;
						position: absolute;
						top: 50%;
						right: 0;
						margin-top: $nav-item-before-size / -2;
						line-height: $nav-item-before-size;
						width: $dropdown-spacing + $nav-item-before-size / 2;
						text-align: left;
						opacity: 0.5;
						font-family: FontAwesome;
						font-size: $nav-item-before-size;
						color: #FFF;
						transition: width $transition-linear;
					}
				}

				&.dropdown-open > .theme-nav-link:before {
					color: $secondary-color;
					width: $dropdown-spacing * 0.2 + $nav-item-before-size;
					opacity: 1;
				}

				> .theme-nav-link {
					color: $dropdown-text-color;
					display: table-cell;
					vertical-align: middle;
					padding: $dropdown-spacing/2 $dropdown-spacing;
					font-weight: 400;
					line-height: 120%;
					vertical-align: middle;

					&:not(x):hover {
						// :not() for specificity over selected state
						text-decoration: none;
						background: $dropdown-hover-color;
					}
				}

				&.hasChild.dropdown-open > .theme-nav-link {
					background: $dropdown-hover-color;
				}

				&.selected > .theme-nav-link {
					text-decoration: none;
					background: $dropdown-selected;
				}

				&.disabled > .theme-nav-link {
					color: $dropdown-text-disabled;

					&:hover {
						color: $dropdown-text-disabled-hover;
					}
				}
			}

			&[data-nav-level="2"] {
				margin-top: -$dropdown-shift-vert;
			}

			&:not([data-nav-level="2"]) {
				// margin-top: -1px;  // ofset for 1px boder
				margin-left: -$dropdown-shift-horiz;
				background-color: $dropdown-color-2;

				> .theme-nav-item {
					> .theme-nav-link {
						&:not(x):hover {
							// :not() for specificity over selected state
							background: $dropdown-selected-2;
						}
					}

					&.selected > .theme-nav-link {
						background: $dropdown-selected-2;
					}
				}
			}

			&.dropdown-align-right:not([data-nav-level="2"]) {
				margin-left: $dropdown-shift-horiz;
			}

			&.dropdown-opening {
				display: block;
				opacity: 0;
			}

			&.dropdown-open {
				display: block;
				opacity: 1;

				&[data-nav-level="2"] {
					margin-top: 0;
				}

				&:not([data-nav-level="2"]) {
					margin-left: 0;
				}
			}
		}
	}

	.theme-search-wrapper {
		border-bottom: $site-search-border;
		color: $site-tagline-text-color;
		background-color: $site-tagline-color;
		overflow: hidden;
		font-family: $fontOne;
		font-size: em(12); // display: none;
	}

	.site-tagline-text {
		padding: 0 $site-tagline-spacing;
		display: block;
		overflow: auto;
		white-space: nowrap;
		@include flex(1, 1, 100%);
	}

	.theme-search-bar {
		line-height: $site-tagline-height;
		@include flex-box(row);

		.theme-page-search {
			@include flex(0);
		}
	}

	.theme-main-nav {
		position: relative;
		font-size: $main-nav-font-size;
		color: $main-nav-text-color;
		background-color: $main-nav-color; // border-bottom: $site-tagline-separator;

		.theme-nav {
			line-height: $main-nav-height;
			display: flex;
			flex-flow: row wrap;
			justify-content: flex-end;

			> .theme-nav-item {
				> .theme-nav-link {
					&:before {
						@include navItemBefore;
					}
				}

				&.dropdown-open > .theme-nav-link:before,
				&.selected > .theme-nav-link:before,
				&:hover > .theme-nav-link:before {
					@include navItemBeforeHover;
				}
			}

			> .theme-nav-item {
				> .theme-nav-link {
					color: $main-nav-text-color;
					text-transform: uppercase;
					padding: 0 $main-nav-spacing;
					white-space: nowrap;

					&:not(x):hover {
						background-color: $main-nav-hover-color;
						color: $main-nav-text-hover-color;
					}
					// :not() for specificity over selected state
				}

				&.disabled {
					> .theme-nav-link {
						color: $main-nav-text-disabled;
					}

					&:hover > .theme-nav-link {
						color: $main-nav-text-disabled-hover;
					}
				}

				&.selected > .theme-nav-link {
					background-color: $main-nav-selected;
					color: $main-nav-text-hover-color;
				}
			}
		}
	}

	.theme-sub-nav {
		position: relative;
		font-size: $sub-nav-font-size;
		background-color: $sub-nav-color; // border-bottom: $site-tagline-separator;

		.theme-nav {
			line-height: $sub-nav-height;

			> .theme-nav-item.disabled {
				> .theme-nav-link {
					color: $sub-nav-text-disabled;
				}

				&:hover > .theme-nav-link {
					color: $sub-nav-text-disabled-hover;
				}
			}

			> .theme-nav-item {
				> .theme-nav-link {
					color: $sub-nav-text-color;
					text-transform: none;
					padding: 0 $sub-nav-spacing;

					&:not(x):hover {
						background-color: $sub-nav-hover-color;
					}
					// :not() for specificity over selected state
				}

				&.selected > .theme-nav-link {
					background-color: $sub-nav-selected;
				}
			}
		}
		// &:empty {
		// display: none;
		transition: height $transition-linear;
		height: $sub-nav-height;

		.nav-fixed & {
			height: $sub-nav-height-fixed;
		}
		// }
	}

	.theme-main-nav,
	.theme-search-wrapper,
	.theme-sub-nav {
		width: 100%;
		margin: 0 auto;
	}

	.theme-nav-style-dropdown,
	.theme-search-bar {
		position: relative;
		margin: auto;
		width: 100%;
		max-width: $maxWidth;
		transition: padding $transition-linear, max-width $transition-linear, left $transition-linear, height $transition-linear, line-height $transition-linear;
		@extend %clearfix;
	}

	&.has-site-logo {
		.theme-main-nav .theme-nav-style-dropdown[data-nav-level="1"],
		.theme-search-bar {
			max-width: $maxWidth;
			padding-left: $site-logo-width;
		}
	}
}
// 3. Navigation and Logo

#topNav.nav-fixed {
	top: 0;
	position: fixed;

	.theme-search-bar {
		line-height: $site-tagline-height-fixed;
		height: $site-tagline-height-fixed;
	}

	.theme-main-nav {
		.theme-nav {
			line-height: $main-nav-height-fixed;
		}

		.theme-nav-style-dropdown > .theme-nav-item {
			> .theme-nav-link:before {
				@include navItemBeforeFixed;
			}
		}
	}

	.theme-sub-nav {
		.theme-nav {
			line-height: $sub-nav-height-fixed;
		}
	}

	&.has-site-logo {
		.theme-nav-style-dropdown,
		.theme-search-bar {
			padding-left: $site-logo-width-fixed;
		}
	}
}
// Placeholder styles for when top nav goes fixed. If there is no sub nav and no
// banner graphic, this fills in the gap and visually blends with the page content.

#topNavPlaceholder {
	// border-bottom: $sub-nav-height solid #fff;
	max-width: $maxWidth;
	margin: auto;
	transition: height $transition-linear;
	height: $nav-placeholder-height;

	&.page-has-banner {
		border-color: transparent;
	}

	.has-sub-nav & {
		height: $nav-placeholder-height;
	}
	// .has-sub-nav .home & { height: $nav-placeholder-height; }
}

#topNav.nav-fixed+#topNavPlaceholder {
	height: $nav-placeholder-height-fixed;
}
// Add padding to html to accound for collapsing nav (and avoid flicker animation)

html {
	transition: padding-bottom $transition-linear; // &.nav-fixed { padding-bottom: $nav-placeholder-height - $nav-placeholder-height-fixed; }
}
// If subnav is not present, the site banner should slide up under the site logo.

html.has-main-nav:not(.has-sub-nav) {
	// #siteHeader { margin-top: -$sub-nav-height; }
	// #topNav.nav-fixed ~ #siteHeader { margin-top: -$sub-nav-height-fixed; }
}
// Social Media Icons

#topNav .theme-search-bar {
	justify-content: flex-end;

	.site-tagline-text {
		display: none;
	}
}

.theme-search-bar .global-social-links {
	display: flex;
	flex-flow: row;
	justify-content: center;
	align-items: flex-end;

	.sn-social-media-icon {
		font-size: 25px;
		background-color: $secondary-color;

		&:hover {
			background-color: $primary-color;
		}
	}
}

.sn-social-media-list .sn-social-media-icon[href*="snapchat"]:before {
	content: "\f2ac";
}

.theme-search-bar .global-login-btn {
	.linkElement {
		h4 {
			a {
				border-radius: 10px;
				background-color: $secondary-color;
				padding: 6px 12px 5px;
				margin-left: 10px;
				margin-top: 7px;
				transition: all 0.2s ease;

				&:hover {
					background-color: $primary-color;
				}

				&:after {
					display: none;
				}
			}
		}
	}
}

.site-footer-social {
	.global-login-btn-footer {
		.linkElement {
			h4 {
				a {
					border-radius: 100%;
					background-color: #ffffff;
					color: $secondary-color;
					padding: 0;
					margin-left: 10px;
					margin-top: 0;
					transition: all 0.2s ease;
					font-size: 1.2em;
					height: 74px;
					display: flex;
					align-items: center;
					width: 74px;
					justify-content: center;

					&:hover {
						background-color: #d1d3d4;
					}

					&:after {
						display: none;
					}
				}
			}
		}
	}
}
