// 5. Site Footer
.snFooterContainer {
  background-color: #eee;
}

#siteFooter {
  @extend %clearfix;
  display: block;
  width: 100%;
  max-width: $maxWidth;
  margin: 0 auto;
  padding: 10px 20px;
  position: relative;
  vertical-align: bottom;
  font-size: .8em;
  clear: both;

  > ul {
    padding: 0;
    text-align: center;
    @include displayCenter($maxWidth);

    li {
      display: inline-block;
      padding: 0 .2em;
      min-height: 20px;

      a {
        display: inline-block;
      }

      &:hover {
        display: inline-block;
      }
    }
  }
}

#siteFooter li#poweredByNGIN {
  a {
    display: block;
    width: 120px;
    height: 20px;
    position: relative;

    &:after {
      content: url($asset-path-for+'/logo_images/logo_black.svg');
      display: block;
      position: absolute;
      top: 4px;
      left: 0;
      width: 100%;
    }

    img {
      display: none;
    }
  }
}

@media only screen and (max-width: 1024px) {
  #siteFooter {
    li {
      min-height: 0;
    }
  }

  #siteFooter li#poweredByNGIN {
    // padding: 10px 0;
    width: 100%;
    a {margin: 0 auto;}
  }
}

@media only screen and (max-width: 640px) {
  #siteFooter {
    li {
      min-height: 0;
    }
  }

  #siteFooter li#poweredByNGIN {
    // padding: 10px 0;
  }
}


.site-footer-custom{
  background-color: $primary-color;
  padding: ($page-element-margin * 2) $page-element-margin;
  > div {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    max-width: $maxWidth;
    width: 100%;
    margin: 0 auto;
  }
}
.site-footer-content{
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  color: #FFF;
  a, a:hover{
    color: #FFF;
  }
  .footer-content-item{
    flex-grow: 1;
    padding: $page-element-margin;
    margin: $page-element-margin 0;
    + .footer-content-item{
      border-left: 1px solid #FFF;
      @media screen and (max-width: 560px){
        border-left: 0;
      }
    }
    p{
      margin-bottom: 0;
    }
    &:first-child{
      @media screen and (max-width: 560px){
        width: 100%;
        flex-basis: 100%;
      }
    }
  }
}

.site-footer-social{
  background-color: $secondary-color;
  padding: $page-element-margin;
  .global-social-links{
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    .sn-social-media-icon{
      font-size: 75px;
      color: $secondary-color;
      background-color: #FFF;
      margin: 7px $page-element-margin;
      &:hover{
        background-color: $gray;
      }
    }
  }
}

.site-footer-logo{
  @media screen and (max-width: 700px){
    width: 100%;
    flex-basis: 100%;
  }
  padding: $page-element-margin;
  .sn-site-logo-wrap{
    display: table;
    table-layout: fixed;
    width: 167px;
    height: 214px;
    margin: auto;
  }
  .sn-site-logo-background{
    display: table-cell;
    vertical-align: middle;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
  }
}
