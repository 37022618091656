$site-logo-size: $site-tagline-height + $main-nav-height !default;
$site-logo-size-fixed: $sub-nav-height-fixed + $main-nav-height-fixed !default;
$site-logo-width: ceil($site-logo-size * 0.73) !default;
$site-logo-width-fixed: ceil($site-logo-size-fixed * 0.73) !default;
$site-logo-offset: 0px !default;
$site-logo-mobile-height: 55px !default;
$site-logo-mobile-width: ceil($site-logo-mobile-height * 0.73) !default;
$mobile-nav-background-color: $primary-color !default;

#sn-site-logo {
  position: absolute;
  top: 0;
  left: 0;
  // left: 50%;
  // transform: translateX(-50%);
  width: 100%;
  max-width: $maxWidth;
  height: 1px;
  z-index: 1;

  .nav-fixed & {
    .sn-site-logo-wrap {
      width: $site-logo-width-fixed;
      height: $site-logo-size-fixed;
    }
  }

  .sn-site-logo-wrap {
    display: table;
    table-layout: fixed;
    width: $site-logo-width;
    height: $site-logo-size + $site-logo-offset;
    margin-top: $site-logo-offset;
    transition: all $transition-linear;
    padding: 5px;
  }

  .sn-site-logo-background {
    display: table-cell;
    vertical-align: middle;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
  }
}
#ngin15868{
  .nav-fixed #sn-site-logo {
    .sn-site-logo-wrap {
      width: 80px;
      height: 45px;
    }
  }
  .sn-site-logo-wrap {
    width: 210px;
    height: 120px;
  }
}
@media screen and (min-width: $maxWidth){
  html:not(.page-manager-visible){
    #sn-site-logo{
      left: 45%;
      transform: translateX(-50%);
    }
  }
}
@media screen and (min-width: #{$maxWidth + $page-manager-width}){
  .page-manager-visible{
    #sn-site-logo{
      left: 45%;
      transform: translateX(-50%);
    }
  }
}
//
.has-mobile-nav{
  #sn-site-logo{
    position: relative;
    top: auto;
    left: auto;
    width: 100%;
    height: 44px;
    z-index: 0;
    background-color: $mobile-nav-background-color;
    .sn-site-logo-wrap {
      width: $site-logo-mobile-width; // 68
      height: $site-logo-mobile-height; //80
      margin: auto;
    }
  }
  &.nav-fixed #sn-site-logo .sn-site-logo-wrap{
    width: $site-logo-mobile-width;
    height: $site-logo-mobile-height;
  }
}
#ngin15868.has-mobile-nav{
  #sn-site-logo{
    .sn-site-logo-wrap {
      width: 60px;
      height: 44px;
      margin: auto;
    }
  }
  &.nav-fixed .sn-site-logo-wrap{
    width: 60px;
    height: 44px;
  }
}
