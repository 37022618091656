// Code that is shared within multiple partials
$sn-pageManager-width: 60px;
%standard-width{
  max-width: $maxWidth;
  width: 100%;
  margin: 0 auto;
}

%full-width{
  width: 100vw;
  position: relative;
  left: 50%;
  // transform: translateX(-50%); // Use this normally
  margin-left: -50vw; // use this if you need to set fixed background images
  .page-manager-visible & {
    margin-left: calc(-50vw + #{$sn-pageManager-width / 2}); // use this if you need to set fixed background images
    width: calc(100vw - #{$sn-pageManager-width});
  }
}

%full-width-image-background{
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.full-width{
  .user_mode &{
    @extend %full-width;
  }
  &.layout-100{
    padding-left: 0;
    .column{
      padding-left: 0;
      padding-right: 0;
      float: none;
    }
  }
}
.full-width-container{
  .user_mode &{@extend %full-width;}
  .fw-content{
    @extend %standard-width;
    padding: 0 15px;
  }
}
@mixin no-padding{
  .column{
    padding-left: 0;
    padding-right: 0;
  }
}


.background-image{
  .user_mode &{ display: none; }
  .edit_mode &{
    &:before{
      max-width: 300px;
      margin: 0 auto;
      display: block;
      padding: 1em;
      text-align: center;
      content: "This image will be used as the background for this layout container. It will only appear here (as a page element) in edit-mode.";
    }
    img{
      max-width: 300px;
      margin: 0 auto;
      border: 2px solid #FFF;
      box-shadow: 0px 0px 15px rgba(0,0,0,.5);
    }
  }
}
.background-cover-img{
  @extend %full-width-image-background;
}
.compact{
  &.pageEl .pageElement{margin-bottom: 0; margin-top: 0;}
  .pageEl .pageElement{margin-bottom: 0; margin-top: 0;}
  &-top{
    &.pageEl .pageElement{margin-top: 0;}
    .pageEl .pageElement{margin-top: 0;}
  }
  &-bottom{
    &.pageEl .pageElement{margin-bottom: 0;}
    .pageEl .pageElement{margin-bottom: 0;}
  }
}
@media screen and (max-width: 767px){
  .compact:not(.layout-100) .pageEl .pageElement{margin-bottom: 25px;}
  .compact:not(.layout-100) .column:last-of-type .pageEl:last-of-type .pageElement{
    margin-bottom: 0;
  }
}

.layoutContainer{
  &.padding{
    padding-top: 40px;
    padding-bottom: 40px;
  }
  &.padding-top{
    padding-top: 40px;
  }
  &.padding-bottom{
    padding-bottom: 40px;
  }
}


.center-align{
  .fw-content, &.layoutContainer{
    display: flex;
    flex-flow: row wrap;
    align-items: center;
  }
}


.maintain-ratio .video_aspect_ratio {
  padding-bottom: 0;
}
.textBlockElement > h3:only-child{
  margin-bottom: 0;
}

.element-header{
  .textBlockElement > h3 {
    @include special-heading;
  }
  &.red .textBlockElement > h3{
    @include special-heading($secondary-color);
  }
  &.light .textBlockElement > h3{
    @include special-heading(#FFF);
  }
  &.large .textBlockElement > h3{
    @media screen and (min-width: 650px){
      font-size: em(48);
    }
  }
  &.medium .textBlockElement > h3{
    @media screen and (min-width: 650px){
      font-size: em(36);
    }
  }
}

.background-blue{
  background-color: $primary-color;
}
.background-red{
  background-color: $secondary-color;
}


// OBJECT FIT POLYFILL
@mixin object-fit($fit:cover, $pos:center) {
  object-fit: $fit;
  object-position: $pos;
  font-family: 'object-fit: #{$fit}; object-position: #{$pos};';
}



.user_mode{ // hide these only on user_mode
  .theme-nav-item.home, // Hide main nav link "home"
  &.home #topNav .theme-sub-nav .theme-nav, // Hide subnav items on home page
  &.home #topNav .theme-sub-nav, // Hide subnav on home page
  .theme-mobile-nav .theme-nav-menu .theme-nav-item.hasChild:first-child .theme-nav-forward{ // hide the forward arrow of the first mobile nave item
    display: none !important;
  }
}

// Adjust placehlder to account for hidden subnav on home page
#topNavPlaceholder {
  .has-sub-nav .home.user_mode &,
  .home.user_mode &,
   { height: $nav-placeholder-height-no-subnav; }
}
